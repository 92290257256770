import React, { PureComponent } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { TimelineLite, TimelineMax } from 'gsap';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import User12 from '../../static/Our_Team/apply-now.svg';
import TeamBiosCard from '../components/TeamBiosCards';
import * as globalAnimation from '../animations/globalAnimation';
import animateThreeImage, { animateBG, animateBoxSection } from '../animations/TeamBios';

export default class teamBios extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    animateBG(this.tl, '.bg-orange');
    animateThreeImage(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.client-image',
      '#description',
      0,
      40
    );
  }

  animateSectionTwo = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateBoxSection(tl, '.anim-icon', '.box-description');
    }
  };

  animations = inView => {
    if (inView) {
      this.cardAnim();
    }
  };

  animateSecondHeading = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      globalAnimation.animateHeading(tl, '.second-title', '.second-subtitle');
    }
  };

  cardAnim = () => {
    const tl = new TimelineMax();
    tl.to('#senior_team_box', 0.6, { x: 15, y: 18, opacity: 1 }, 0.6);
    tl.to('#senior_team_box_mob', 0.6, { x: 10, y: 12, opacity: 1 }, 0.6);
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne: { description, img1, img2, img3 },
            sectionTWo: { leftDescription, rightDescription },
            cards,
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Team Bios" />
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="main_wrapper">
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>
              </div>
            </Col>
            <div className="wrapper py-0">
              <Row>
                <Col xs={12} lg={6} className="position-relative py-3">
                  <div className="d-none d-lg-block">
                    <div className="d-flex justify-content-center">
                      <div className="team1 m-2 align-self-center client-image">
                        {/* <img src={Team1} className="image-fluid" alt="" /> */}
                        <Img className="img-fluid" fixed={img1.image.childImageSharp.fixed} />
                      </div>
                      <div className="team2 m-2 align-self-top client-image">
                        <Img className="img-fluid" fixed={img2.image.childImageSharp.fixed} />
                      </div>
                    </div>
                    <div className="team3 text-center mx-auto client-image">
                      <Img className="img-fluid" fluid={img3.image.childImageSharp.fluid} />
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <div>
                      <Img className="img-fluid" fluid={img3.image.childImageSharp.fluid} />
                    </div>
                  </div>
                  <div className="bg-orange team-wrapper d-none d-md-block" />
                </Col>
                <Col xs={12} lg={5}>
                  <div className="mt-3 position-relative line line-horizontal">
                    <LightBluekey />
                  </div>
                  <p id="description">{description}</p>
                </Col>
              </Row>
            </div>

            <Container fluid>
              <Observer onChange={this.animateSectionTwo} triggerOnce>
                <Row>
                  <Col xs={12} lg={{ span: 5, offset: 1 }} style={{ backgroundColor: '#F5F5F5' }}>
                    <div className="p-3 p-md-5 m-3">
                      <div className="d-flex justify-content-center anim-icon justify-content-md-start">
                        <img
                          src={leftDescription.image.publicURL}
                          alt=""
                          className="mb-3 mb-md-4"
                          height="90px"
                        />
                      </div>
                      <p className="mr-4 box-description">{leftDescription.description}</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={5}>
                    <div className="p-3 p-md-5 m-3">
                      <div className="d-flex justify-content-center anim-icon justify-content-md-start">
                        <img
                          src={rightDescription.image.publicURL}
                          alt=""
                          className="mb-3 mb-md-4"
                          height="90px"
                        />
                      </div>
                      <p className="mr-4 box-description">{rightDescription.description}</p>
                    </div>
                  </Col>
                </Row>
              </Observer>
            </Container>
            <div className="wrapper pt-0">
              <Observer onChange={this.animateSecondHeading} triggerOnce>
                <Row>
                  <Col>
                    <div className="wrapper px-0">
                      <div className="page_heading_section text-center">
                        <h3 className="page_heading text-uppercase d-lg-block d-none second-title">
                          SENIOR TEAM BIOS
                        </h3>
                        <h4 className="text-uppercase page_heading_child second-title">
                          SENIOR TEAM BIOS
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Observer>
              <Observer onChange={this.animations} triggerOnce className="my-3">
                <Row className="justify-content-center justify-content-lg-start">
                  {cards.map(card => (
                    <TeamBiosCard
                      title={card.title}
                      subtitle={card.subtitle}
                      icons={card.icons}
                      modalIcons={card.modalIcons}
                      descriptions={card.descriptions}
                      image={card.image}
                    />
                  ))}

                  <Col xs={8} md={4} lg={3}>
                    <div className="m-3 m-md-0 my-md-3 border-0 px-0 px-md-3">
                      <div className="card rounded-0">
                        <div
                          className="card-overlay bg-appcolor-dark d-none d-lg-block"
                          id="senior_team_box"
                        />
                        <div
                          className="card-overlay bg-appcolor-dark d-lg-none"
                          id="senior_team_box_mob"
                        />
                        <figure className="m-0">
                          <div className="border-0 position-relative text-center">
                            <img
                              src={User12}
                              alt=""
                              className="h-auto card-img-top border-0 rounded-0"
                            />
                            <div className="centered">
                              <h2 className="text-bold">You?</h2>
                              <div className="modal_btn">
                                <Link to="/apply-now/" className="text-appcolor">
                                  <Button className="text-muted bg-white border-0">
                                    <small className="text-bold text-nowrap">APPLY NOW</small>
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Observer>
            </div>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "team-bios" } }) {
      frontmatter {
        title
        sectionOne {
          img1 {
            image {
              childImageSharp {
                fixed(width: 195, height: 260, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          img2 {
            image {
              childImageSharp {
                fixed(width: 305, height: 305, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          img3 {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
        }
        sectionTWo {
          leftDescription {
            image {
              publicURL
            }
            description
          }
          rightDescription {
            image {
              publicURL
            }
            description
          }
        }
        cards {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icons {
            alt
            image {
              publicURL
            }
            link
          }
          modalIcons {
            alt
            image {
              publicURL
            }
            link
          }
          descriptions
        }
      }
    }
  }
`;
