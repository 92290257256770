import React, { PureComponent } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import Img from 'gatsby-image';
import { animateModal, animateModalMobile } from '../../animations/TeamBios';

export default class TeamBiosCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  animateModalKey = () => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    animateModal(tl, '.svg-line__line_yellow', '.svg_circle_yellow');
    animateModalMobile(tl, '.svg-line__line_yellow_mobile', '.svg_circle_yellow_mobile');
  };

  handleShowModal = () => {
    document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { title, subtitle, descriptions, image, icons, modalIcons } = this.props;
    const { showModal } = this.state;

    return (
      <Col xs={8} md={4} lg={3}>
        <div className="m-3 m-md-0 my-md-3 border-0 px-0 px-md-3 team_bios_card">
          <div className="card rounded-0">
            <div className="card-overlay bg-appcolor-dark d-none d-lg-block" id="senior_team_box" />
            <div className="card-overlay bg-appcolor-dark d-lg-none" id="senior_team_box_mob" />

            <figure className="m-0">
              <div className="border-0">
                <Img className="border-0" fluid={image.childImageSharp.fluid} />
              </div>
              <figcaption className="card-details h-100 fadeIn-bottom p-0">
                <div className="h-100 p-3">
                  <div className="d-flex align-items-end frame center_content h-100 justify-content-between">
                    <div className="m-2">
                      <div className="d-flex flex-lg-column">
                        {icons.map(icon => (
                          <a
                            href={
                              icon.link !== null
                                ? `${icon.alt === 'mail' ? 'mailto:' : ''}${icon.link}`
                                : null
                            }
                            target={`${icon.alt === 'mail' ? '' : '_blank'}`}
                          >
                            <img
                              src={icon.image.publicURL}
                              alt=""
                              className="mr-1 mr-md-0 card_icon"
                              height="20px"
                              width="20px"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="modal_btn">
                      <Button
                        onClick={this.handleShowModal}
                        className="text-muted bg-white border-0 text-decoration-none"
                      >
                        <small className="text-bold team_bio_btn">View Bio</small>
                      </Button>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <h6 className="card-text font-weight-bold mt-md-4 mt-4 mb-0">{title}</h6>
          <small className="text-muted mt-0">{subtitle}</small>
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={this.handleClose}
            onEnter={this.animateModalKey}
            className="team_bios_card"
          >
            <Modal.Header closeButton className="border-0 pb-lg-0"></Modal.Header>
            <Observer onChange={this.animateModalKey}>
              <Modal.Body className="py-0">
                <Row className="justify-content-center">
                  <Col xs={12} md={3}>
                    <div className="px-2 text-center">
                      <Img fluid={image.childImageSharp.fluid} />
                      <h6 className="text-bold mt-2 mb-0">{title}</h6>
                      <small className="text-muted">{subtitle} </small>
                    </div>
                    <div className="pb-lg-2">
                      <div className="d-flex justify-content-center mb-4">
                        {modalIcons.map(icon => (
                          <a
                            href={
                              icon.link !== null
                                ? `${icon.alt === 'mail' ? 'mailto:' : ''}${icon.link}`
                                : null
                            }
                            target={`${icon.alt === 'mail' ? '' : '_blank'}`}
                          >
                            <img
                              src={icon.image.publicURL}
                              alt=""
                              className="mx-1 card_modal_icon"
                              height="25px"
                              width="25px"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={9} className="pr-0">
                    <div className="d-flex justify-content-between">
                      <h5 className="text-bold pr-4">Introduction</h5>

                      <div className="w-100 d-none d-lg-block">
                        <svg viewBox="0 0 300 8" version="1.1" className="rotate180-svg">
                          <g>
                            <rect
                              className="svg-line__line svg-line__line_yellow"
                              x="6.5"
                              y="4"
                              width="290"
                              height="0.1"
                              stroke="#ffba00"
                            />
                            <circle
                              transform="rotate(180, 2, 3.5)"
                              className="svg_circle_yellow"
                              fill="none"
                              cx="-294"
                              cy="3"
                              r="1.6"
                              stroke="#ffba00"
                            />
                          </g>
                        </svg>
                      </div>

                      <div className="w-100 d-lg-none">
                        <svg viewBox="0 0 300 8" version="1.1" className="rotate180-svg">
                          <g>
                            <rect
                              className="svg-line__line svg-line__line_yellow_mobile"
                              x="6.5"
                              y="4"
                              width="284"
                              height="0.1"
                              stroke="#ffba00"
                            />
                            <circle
                              transform="rotate(180, 2, 3.5)"
                              className="svg_circle_yellow_mobile"
                              fill="none"
                              cx="-290"
                              cy="3"
                              r="2.6"
                              stroke="#ffba00"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="pr-4 pb-4" style={{ fontSize: '1em', lineHeight: '150%' }}>
                      {descriptions.map(description => (
                        <p>{description}</p>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
            </Observer>
          </Modal>
        </div>
      </Col>
    );
  }
}
