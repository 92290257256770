import { Power1, Power2 } from 'gsap';

export default function animateThreeImage(tl, svgLine, svgCircle, image, description, X, Y) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      image,
      1.3,
      {
        x: X,
        y: Y,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateBG(tl, element) {
  const o = 0.5;
  return tl.staggerFrom(
    element,
    1,
    {
      x: -200,
      y: 0,
      opacity: 0,
      ease: Power2.easeOut,
    },
    0,
    o
  );
}

export function animateBoxSection(tl, icon, description) {
  const o = 0.7;
  return tl
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(icon, 0.5, { y: 40, autoAlpha: 0 }, 0, o);
}

export function animateModal(tl, svgLine, svgCircle) {
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 1, { attr: { width: 0 } }, { attr: { width: 290 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      1,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 1, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1);
}

export function animateModalMobile(tl, svgLine, svgCircle) {
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 1, { attr: { width: 0 } }, { attr: { width: 284 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      1,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to(
      '.line-horizontal',
      1,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      0.5
    );
}
